import React from 'react';
import PropTypes from 'prop-types';
import AppConst from '../../constants/AppConstants';

function getItems(pages) {
  const { DirectPages, PageItemsPerPage } = AppConst;

  if (pages.count < DirectPages.length + 1) {
    return DirectPages.slice(0, pages.count);
  }

  if (pages.current < DirectPages.length) {
    return DirectPages;
  }

  const items = [];
  const start = (pages.current > pages.count - PageItemsPerPage) ?
    (pages.count + 1) - PageItemsPerPage :
    (pages.current + 1) - Math.floor(PageItemsPerPage / 2);
  for (let page = start; page < start + PageItemsPerPage; page += 1) {
    items.push(page);
  }

  return items;
}

const Pagination = ({ pages, onPrevPage, onNextPage, onGotoPage, onFirstPage, onLastPage }) => {
  if (pages.count === 0) {
    return <div/>;
  }

  const items = getItems(pages);

  const renderFirstButton = () => (
    <li>
      <a onClick={onFirstPage}>
        <img src="/img/page_first.png" className="first" alt="first"/>
      </a>
    </li>
  );
  const renderPrevButton = () => (
    <li>
      <a className="fontcolorgray1 bold paddingleft5 fontsize1p2" onClick={onPrevPage}>
        Back
      </a>
    </li>
  );
  const renderNextButton = () => (
    <li>
      <a className="fontcolorgray1 bold paddinglr5 fontsize1p2" onClick={onNextPage}>
        Next
      </a>
    </li>
  );
  const renderGotoButton = (page) => {
    const current = page === (pages.current + 1);
    return (
      <li key={page}>
        <a className="fontcolorgray1 bold paddinglr10 fontsize1p2" onClick={() => onGotoPage(page - 1)}>
        { current ? <span className="page_selected">{page}</span> : page }
        </a>
      </li>
    );
  };
  const renderLastButton = () => (
    <li>
      <a className="paddingleft10">
        <img src="/img/page_end.png" className="end" alt="end" onClick={onLastPage}/>
      </a>
    </li>
  );
  const renderDotsButton = () => (
    <li>
      <a className="fontcolorgray1 bold paddinglr10 fontsize1p2">
        {'...'}
      </a>
    </li>
  );

  if (pages.count > AppConst.DirectPages.length) {
    if (pages.current < 3 || pages.count - 4 < pages.current) {
      const part1 = [1, 2, 3];
      const part2 = [pages.count - 2, pages.count - 1, pages.count];
      return (
        <div className="pagination_block paddingtb20 paddingleft15">
          <ul className="width100">
              { renderFirstButton() }
              { renderPrevButton() }
              { part1.map(page => renderGotoButton(page)) }
              { renderDotsButton() }
              { part2.map(page => renderGotoButton(page)) }
              { renderNextButton() }
              { renderLastButton() }
          </ul>
        </div>
      )
    }
  }

  if (items.length > 1) {
    return (
      <div className="pagination_block paddingtb20 paddingleft15">
        <ul className="width100">
          { pages.current !== 0 ? renderFirstButton() : null }
          { pages.current !== 0 ? renderPrevButton() : null }
          { items.indexOf(1) === -1 ? renderDotsButton() : null }
          { items.map(page => renderGotoButton(page)) }
          { items.indexOf(pages.count) === -1 ? renderDotsButton() : null }
          { pages.current !== (pages.count - 1) ? renderNextButton() : null }
          { pages.current !== (pages.count - 1) ? renderLastButton() : null }
        </ul>
      </div>
    );
  }

  return null;
};

Pagination.propTypes = {
  pages: PropTypes.object.isRequired,
  onFirstPage: PropTypes.func.isRequired,
  onPrevPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onGotoPage: PropTypes.func.isRequired,
  onLastPage: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  pages: {},
};

export default Pagination;
