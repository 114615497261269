import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RingLoader } from 'react-spinners';
import { push } from 'react-router-redux';
import { LoginComponent as LC } from '../components';
import BabuRouteConst from '../constants/BabuRouteConst';
import { DataActions, UserActions } from '../actions';
import config from '../config';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      loading: false,
    };

    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
  }

  componentDidMount() {
  }

  responseGoogle(response) {
    const { auth, dispatch } = this.props;

    if (response.error) {
      dispatch(DataActions.setError(response.error));
    } else {
      auth.loginWithGoogle(response);
      this.props.dispatch(UserActions.setProfile(response.profileObj));
      this.props.dispatch(push(BabuRouteConst.home));
    }
  }

  responseFacebook(response) {
    const auth = this.props.auth;

    if (response.error) {
      console.error(response.error);
    } else {
      auth.loginWithFacebook(response);
      const profile = {
        name: response.name,
        userName: response.username,
        userId: response.id,
        email: response.email,
      };
      this.props.dispatch(UserActions.setProfile(profile));
      this.props.history.push(BabuRouteConst.home);
    }
  }

  responseTwitter(response) {
    console.log(response);
  }

  loginAuth0() {
    this.props.auth.login();
  }

  renderSignInWithEmail() {
    return (
      <form name="signin">
        <div className="fontcolorgray1">
          USERNAME
        </div>
        <div className="paddingtb5">
          <input type="text" name="username" className="username" value={this.state.username} required />
        </div>
        <div className="fontcolorgray1 paddingtop20">PASSWORD</div>
        <div className="paddingtb5">
          <input type="password" name="password" className="password" value={this.state.password} required />
        </div>
        <div className="paddingtop20">
          <input type="submit" className="enter" value="ENTER" />
        </div>
        <div className="fontcolorgray1 paddingtb20">
          <a className="fogotpass fontcolorgray1">Forgot Password?</a>
        </div>
      </form>
    );
  }

  renderSignInBox() {
    return (
      <div className="width49 floatright rsp_width_signin_block">
        <div className="width75 marginauto textcenter">
          <h1>Sign In</h1>
        </div>
        <div className="width65 marginauto textcenter fontcolorgray1">
          VIA SOCIALS
        </div>
        <div className="width65 marginauto textcenter paddingtop20">
          {/* <LC.TwitterButton
            requestTokenUrl="https://api.twitter.com/oauth/request_token"
            onSuccess={this.responseTwitter}
            onFailure={this.responseTwitter}
          /> */}
          {/* <LC.FacebookButton
            appId={config.FACEBOOK_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
          /> */}
          <LC.GoogleButton
            clientId={config.GOOGLE_CLIENT_ID}
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
          />
        </div>
        <div className="width75 marginauto textcenter">
          <fieldset className="fieldsetmargin relativeblock">
            <legend />
            <div className="inlineblock marginauto padding20 bgcolorwhite fontcolorgray1 fontsize1p3" style={{marginTop: '-52px'}}>or</div>
          </fieldset>
        </div>
        <div className="width75 marginauto textcenter">
          { this.renderSignInWithEmail() }
        </div>
      </div>
    )
  }

  renderSignInHeader() {
    return (
      <div className="width49 floatleft rightline rsp_width_signin_desciption">
        <div className="height25">&nbsp;</div>
        <div className="width75 marginauto textcenter">
          <img src="img/Babu_small.png" className="img-responsive marginauto" alt="logo" />
        </div>
        <div className="height75">&nbsp;</div>
        <div className="width65 marginauto textcenter fontsize1p3 fontcolorgray1 rsp_width_signin1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
        <div className="height75">&nbsp;</div>
        <div className="width65 marginauto textcenter fontcolorgray1">
          FOLLOW US:
        </div>
        <div className="width65 marginauto textcenter paddingtop20">
          <a href="https://www.facebook.com" className="paddinglr10"><img src="img/facebook_green.png" alt="facebook" /></a>
          <a href="https://www.twitter.com" className="paddinglr10"><img src="img/twitter_green.png" alt="twitter" /></a>
          <a href="https://www.googleplus.com" className="paddinglr10"><img src="img/googleplus_green.png" alt="googleplus" /></a>
          <a href="https://www.instagram.com" className="paddinglr10"><img src="img/instagram_green.png" alt="instagram" /></a>
          <a className="paddinglr10"><img src="img/rss_green.png" alt="rss" /></a>
        </div>
      </div>
    )
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="_content">
          <div className="_grid-one _align-center" style={{ height: '90vh' }}>
            <div className="_grid-block _align-middle">
              <div className="sweet-loading">
                <RingLoader
                  color={'#123abc'}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="body">
          <div className="width90 marginauto paddingtb25 rsp_width_signin">
            { this.renderSignInBox() }
            { this.renderSignInHeader() }
            <div className="clearboth" />
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function select() {
  return {};
}

export default connect(select)(Login);
