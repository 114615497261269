import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import utils from '../../../utils';

const ItemGridCard = ({ item, index }) => {
  const { _source } = item;
  const { filename, extension, tags } = _source;

  const imageSrc = utils.imageSrc(extension, 'grid');

  return (
    <div className="_grid-one _margin-bottom">
      <Link to={`/home/detail/${index}`} className="__link">
        <img className="__image-grid" src={imageSrc} alt={item} />
      </Link>
      <div className="_align-top margin-none">
        <Link to={`/home/detail/${index}`} className="__link">
          <h5 className="_color-blue">{filename}</h5>
        </Link>
        <div className="__doc_tags _margin-none">
          <p className="_font-small _font-bold _color-red">{tags}</p>
        </div>
      </div>
    </div>
  );
};

ItemGridCard.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};

ItemGridCard.defaultProps = {
  item: {},
  index: -1,
};

export default ItemGridCard;
