import React from 'react';
import PropTypes from 'prop-types';

function renderSearchStatus(loading, total, count, pages) {
  const start = pages.current * pages.size + 1;
  const end = start + count - 1;

  if (loading) {
    return (
      <span className="fontcolorgray1 fontsize1p1">
        {'Loading...'}
      </span>
    )
  }

  return (
    <span className="fontcolorgray1 fontsize1p1">
      {total > 0 && start > 0 ?
        start !== end ?
          `Results ${start} - ${end} of ${total}`
          : `Results ${start} of ${total}`
        : null
      }
    </span>
  );
}

function renderChips(selected, onClick) {
  let chips = [];

  for(let filter in selected) {
    chips.push(
      <span className="fontcolorgray1 fontsize1p1 paddinglr20"> | </span>,
      <span className="fontcolorgray1 fontsize1p1">
        {`${filter.title}: ${filter.value}`}
      </span>,
      <img
        src="img/x.png"
        className="x paddingleft5 x_icon"
        alt="cancel"
        onClick={() => onClick(filter)}
      />
    )
  };

  return chips;
}

const FilterBox = ({loading, total, count, pages, selected, onRemoveFilter}) => {
  return (
    <div className="info_block">
      { renderSearchStatus(loading, total, count, pages) }
      {
        renderChips(selected, onRemoveFilter)
      }
    </div>
  );
};

FilterBox.propTypes = {
  onRemoveFilter: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func,
};

export default FilterBox;
