import React from 'react';
import PropTypes from 'prop-types';
import utils from '../../../utils';

const ItemListCard = ({ item, index, handleDownload, handleShare }) => {
  if (!item) {
  return <div />;
  }

  const { _source } = item;
  const { filename, extension, tags, txt, deeplink } = _source;
  const imageSrc = utils.imageSrc(extension, 'list');
  const styles = {};
  styles.viewblock = {
    display: 'inline-block'
  };
  styles.noneblock = {
    display: 'none'
  };
  const viewstyle = tags ? styles.viewblock : styles.noneblock;

  return (
    <div className="content_block paddingtb15">
      <div className="width12 floatleft textcenter rsp_width_center_side">
        <img src={imageSrc} className="pdf thumb_img" alt={filename} width={79} height={80}/>
      </div>
      <div className="width70 floatleft paddinglr10 rsp_width_center_center">
        <div className="fontsize1p5 fontcolorblue1">
          <a className="wordbreakall" href={deeplink} target="_blank">{filename}</a>
          <a className="relativeblock">
			<div className="loader" style={viewstyle}>
				<div className="inner one"></div>
				<div className="inner two"></div>
				<div className="inner three"></div>
			</div>
			<span className={tags ? "inlineblock relativeblock servicebtn" : "noneblock"}>{tags}
				<img src="img/btn_effect.png" className="effect" alt={tags} />
			</span>
          </a>
        </div>
        <div className="fontcolorgray6 fontsize1p1 paddingtb5">
        </div>
        <div className="bold">
        </div>
      </div>
      <div className="width12 floatright rsp_width_center_side">
        <a
          className="width100 inlineblock fontwhitecolor bgcolorgreen textcenter borderradius3 donwload"
          onClick={handleDownload}>DOWNLOAD</a>
        <a
          className="width100 inlineblock fontcolorgray5 textcenter borderradius3 bordercolorgray1 share"
          onClick={handleShare}>SECURE SHARE</a>
      </div>
      <div className="clearboth"></div>
    </div>
  );
};

ItemListCard.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};

ItemListCard.defaultProps = {
  item: {},
  index: -1,
};

export default ItemListCard;
