import {
  SET_DROPBOX,
  SET_PROFILE,
} from '../constants/ActionConstants';

// const assign = Object.assign || require('object.assign');

const initialState = {
  profile: {},
  dropbox: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DROPBOX:
      return Object.assign({}, state, { dropbox: action.dropbox });
    case SET_PROFILE:
      return Object.assign({}, state, { profile: action.profile });
    default:
      return state;
  }
};
