const config = {
  FACEBOOK_APP_ID: '442805582583015',
  GOOGLE_CLIENT_ID: '947593792502-sgch2vc2ljsraprfilofeus5rfc5qfi5.apps.googleusercontent.com',
  TWITTER_CONSUMER_KEY: 'k1hPVmAjKQVbPVSRSzvl5RL57',
  TWITTER_CONSUMER_SECRET: 'k1hPVmAjKQVbPVSRSzvl5RL57',
  AWS_ACCESS_KEY: 'AKIAINHJBWLR53S6URTA',
  AWS_SECRET_KEY: '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',
  OAUTH_CONNECTOR_URL: 'https://oauthconnector-dev.p3gkyjpmjt.us-east-1.elasticbeanstalk.com:8443',
  API_HOST: 'ow2srz8l6d.execute-api.us-east-1.amazonaws.com/prod',
  DROPBOX_AUTH_URL: 'https://www.dropbox.com/oauth2/authorize?response_type=code&client_id=zh5ija7veye1vx4&state=<username>&force_reauthentication=true',
  GOOGLE_AUTH_URL: 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&scope=https://www.googleapis.com/auth/drive%20https://www.googleapis.com/auth/drive.file%20https://mail.google.com/&access_type=offline&client_id=545526681832-cmh5decnbpce9t6g4d352u6k7q55m062.apps.googleusercontent.com&state=<username>',
  REDIRECT_URI2: 'https://ow2srz8l6d.execute-api.us-east-1.amazonaws.com/prod/redirect2',
  REDIRECT_URI: 'https://ow2srz8l6d.execute-api.us-east-1.amazonaws.com/prod/redirect',
  ACCOUNT_AUTH_REDIRECT_URI_GOOGLE: 'https://www.babuassist.com/callback/google',
  ACCOUNT_AUTH_REDIRECT_URI_DBOX: 'https://www.babuassist.com/callback/dropbox'
};

export default config;
