import React from 'react';

export default () => (
  <div className="row header_bgcolor paddingtb35">
    <div className="width49 marginauto inlineblock floatleft rsp_main_title">
      <div className="marginauto">
        <h1 className="fontcolorblue1 paddingtop10p paddingleft10p rsp_main_title_fontsize">
          Life's admin tasks should be easy<br/>Let Babu do it for you
        </h1>
      </div>
      <div className="width80 marginauto">
        <div className="fontsize1p3 paddingtop20 fontcolorgray2">
        </div>
      </div>
      <div className="width80 marginauto paddingtop25">
        <a className="inlineblock fontwhitecolor bgcolorgreen textcenter borderradius5 signbutton">
          SIGN UP FOR FREE
        </a>
      </div>
    </div>
    <div className="width49 marginauto inlineblock floatright relativeblock rsp_main_img">
      <img
        src="img/rotate45_badu_log.png"
        className="img-responsive absoluteblock logabolutecontrol"
        alt="logo"
      />
      <img
        src="img/badu_main.png"
        className="img-responsive floatright relativeblock"
        alt="main"
      />
    </div>
    <div className="clearboth" />
  </div>
)