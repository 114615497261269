// import aws4 from 'aws41';
import {
  ELASTIC_SEARCH,
  ELASTIC_SEARCH_SUCCESS,
  ELASTIC_SEARCH_FAIL,
  ELASTIC_SEARCH_PAGE,
  ELASTIC_SEARCH_PAGE_SUCCESS,
  ELASTIC_SEARCH_PAGE_FAIL,
  CLEAR_DATA,
  SET_ERROR,
} from '../constants/ActionConstants';
import config from '../config';
import { searchResult } from '../data';

const fetch = global.fetch;
const API_URL = `https://${config.API_HOST}/search`;

function prepareData(payload) {
  // add email and access_token
  // const email = localStorage.getItem('email') || 'missing email';
  const id_token = localStorage.getItem('id_token') || '';
  const access_token = localStorage.getItem('access_token') || '';

  payload.it = id_token;
  // payload.index = 'priteshpatel15@gmail.com';

  const body = JSON.stringify(payload);
  // const opts = {
  //   host: API_HOST,
  //   path: '/prod',
  //   service: 'execute-api',
  //   region: 'us-east-1',
  //   body,
  // };

  // aws4.sign(opts, {
  //   secretAccessKey: config.AWS_SECRET_KEY,
  //   accessKeyId: config.AWS_ACCESS_KEY,
  // });

  // opts.headers['at'] = access_token;
  // opts.headers['it'] = id_token;
  // opts.headers['t'] = 'g';

  const headers = {
    at: access_token,
    it: id_token,
    t: 'g',
  };

  const datas = {
    method: 'POST',
    headers,
    mode: 'cors',
    body,
  };

  return datas;
}

/**
 * Send query to
 * @param payload
 * @returns {function(*)}
 */
function searchElastic(payload) {
  const datas = prepareData(payload);

  // console.log(datas);

  return (dispatch) => {
    dispatch({ type: ELASTIC_SEARCH, payload });
    fetch(API_URL, datas)
      .then(res => res.json())
      .then(result => {
        const data = result;
        // const data = searchResult;
        if (data.message) {
          dispatch({
            type: ELASTIC_SEARCH_FAIL,
            err: data.message,
          });
        } else {
          dispatch({
            type: ELASTIC_SEARCH_SUCCESS,
            data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ELASTIC_SEARCH_FAIL,
          err,
        });
      });
  };
}

function searchElasticPage(payload, page) {
  const datas = prepareData(payload);

  return (dispatch) => {
    dispatch({ type: ELASTIC_SEARCH_PAGE, payload });

    fetch(API_URL, datas)
      .then(res => res.json())
      .then((result) => {
        // const data = parse(result).body;
        // const data = parseResult(result);
        const data = result;
        if (data.message) {
          dispatch({
            type: ELASTIC_SEARCH_PAGE_FAIL,
            err: data.message,
          });
        } else {
          dispatch({
            type: ELASTIC_SEARCH_PAGE_SUCCESS,
            data,
            page,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ELASTIC_SEARCH_PAGE_FAIL,
          err,
        });
      });
  };
}

function clearData() {
  return { type: CLEAR_DATA };
}

function setError(err) {
  return {
    type: SET_ERROR,
    err,
  };
}

const actions = {
  searchElastic,
  searchElasticPage,
  clearData,
  setError,
};

export default actions;
