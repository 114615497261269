import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import App from './App';
import createStore from './Store';
import { PersistGate } from 'redux-persist/integration/react'
import registerServiceWorker from './registerServiceWorker';

const { store, persistor } = createStore()

ReactDOM.render(
  <IntlProvider locale="en">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>  
    </Provider>
  </IntlProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
