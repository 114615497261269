import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import utils from '../../../utils';

const ItemDetailCard = ({ item }) => {
  const { _source } = item;
  const { filename, extension, tags } = _source;

  // const txt = _source.txt.replace(/(?:\\r\\n|\\r|\\n)/g, '<br />').replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
  const txt = _source.txt.replace(/(?:\r\n|\r|\n)/g, '<br />');
  const imageSrc = utils.imageSrc(extension, 'detail');

  console.log(imageSrc);

  return (
    <div className="_grid-one-seven">
      <div>
        <img className="__image-detail" src={imageSrc} alt={filename} />
        <Link to="/home" className="_font-bold _color-blue __link">
          <p className="_color-blue _font-bold _align-right">Back</p>
        </Link>
      </div>
      <div>
        <Link to="#" className="_font-bold _color-blue __link">
          <h2 className="_color-blue _font-bold">{filename}</h2>
        </Link>
        <p className="_padding-none _font-small _font-bold _color-red">{tags}</p>
        <p className="_padding-none">
          <span dangerouslySetInnerHTML={{ __html: txt }} />
        </p>
      </div>
    </div>
  );
};

ItemDetailCard.propTypes = {
  item: PropTypes.object,
  // toggleDisplayOption: PropTypes.func.isRequired,
};

export default ItemDetailCard;
