import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FilterActions } from '../../../actions';

class Section extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      collapsed: false,
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.toggleTitle = this.toggleTitle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.filter.input,
    });
  }

  handleToggleCheck(key) {
    const { filter } = this.props;

    filter.buckets.forEach((bucket) => {
      if (bucket.key === key) {
        bucket.checked = !bucket.checked;
      }
    });

    this.props.dispatch(FilterActions.setFilter(filter));
  }

  handleChangeInput(e) {
    this.setState({
      value: e.target.value,
    });
  }

  toggleTitle() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  renderInput() {
    const { value } = this.state;

    return (
      <div className="sub_search_block">
        <input type="text"
               name="search"
               className="sub_search"
               placeholder="Search"
               value={value}
               onChange={this.handleChangeInput}
        />
        <img src="img/sub_searchbtn.png" className="sub_searchbtn" alt="search" />
      </div>
    );
  }

  renderItems(filter) {
    const { value } = this.state;
    const items = value ?
      filter.buckets.filter(bucket => bucket.key.indexOf(value) !== -1) : filter.buckets;

    return items.map(bucket => {
      const className = bucket.checked ? 'checked' : 'unchecked';
      const bucketKey = bucket.key.length > 22 ? bucket.key.substring(0, 20) + '..' : bucket.key

      return (
        <div className="checkbox_block paddingtb5 paddingtop10 paddingleft10" key={bucket.key}>
          <input type="checkbox"
                 name="check"
                 className={className}
                 checked={bucket.checked}
                 onChange={() => this.handleToggleCheck(bucket.key)}
          />
          <img src={`img/${className}.png`}
               className={`${className}btn`}
               alt={className}
               onClick={() => this.handleToggleCheck(bucket.key)}
          />
          <label className="fontnormal paddingleft10">
            {bucketKey}
          </label>
          <label style={{float: 'right', paddingTop: 5}}>{bucket.doc_count}</label>
        </div>
      )
    });
  }

  renderFilterList(filter) {
    const { collapsed } = this.state;
    const { title } = filter;

    if (collapsed) {
      return (
        <div className="_margin-bottom">
        </div>
      );
    }

    return (
      <div className="side_block">
        <h4 className="">{title}</h4>
        { this.renderInput() }
        { this.renderItems(filter) }
      </div>
    );
  }

  render() {
    const { filter } = this.props;

    switch (filter.type) {
      case 'list':
        return this.renderFilterList(filter);
      default:
        return <div />;
    }
  }
}

Section.propTypes = {
  filter: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Section;
