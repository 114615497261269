import * as Login from './Login';
import * as Account from './Account';
import * as Home from './Home';
import * as Landing from './Landing';
import * as Contact from './Contact';

export { default as Logo } from './Logo';
export { default as HeaderMenu } from './HeaderMenu';

export const LoginComponent = Login;
export const HomeComponent = Home;
export const AccountComponent = Account;
export const LandingComponent = Landing;
export const ContactComponent = Contact;