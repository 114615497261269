import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropbox from 'dropbox';
import axios from 'axios';
import utils from '../../utils';
import config from '../../config';

const window = global.window;
const screen = global.screen;

class DropboxButton extends Component {
  static getAccessTokenFromUrl(popup) {
    return utils.parseQueryString(popup.location.hash).access_token;
  }

  constructor(props) {
    super(props);
    this.popup = null;
  }

  polling(popup) {
    const { onSuccess, onFailure } = this.props;

    const polling = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
        onFailure(new Error('Popup has been closed by user'));
      }

      const closeDialog = () => {
        clearInterval(polling);
        popup.close();
      };

      try {
        if (popup.location.hostname.includes('localhost')) {
          const accessToken = this.getAccessTokenFromUrl(popup);
          onSuccess(null, accessToken);
          closeDialog();
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in IE.
      }
    }, 500);
  }

  openPopup(url) {
    const params = {
      width: 600,
      height: 500,
      left: (screen.width - 600) / 2,
      top: (screen.height - 500) / 2,
      resizable: 'yes',
      scrollbars: 'yes',
      toolbar: 'yes',
      menubar: 'no',
      location: 'no',
      directories: 'no',
      status: 'yes',
    };
    const options = Object.keys(params).map(key => `${key}=${params[key]}`).join(',');

    this.popup = window.open(url, 'popUpWindow', options);

    this.polling(this.popup);
  }

  authenticate() {
    const { authUrl } = this.props

    window.open(authUrl);
  }

  connectTo() {
    const dbx = new Dropbox({ clientId: this.props.clientId });
    const authUrl = dbx.getAuthenticationUrl();

    this.openPopup(authUrl);
  }

  connectTo2(e) {
    const { email, onSuccess, onFailure } = this.props;

    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    const id_token = localStorage.getItem('id_token') || '';
    const access_token = localStorage.getItem('access_token') || '';
    const headers = {
      at: access_token,
      it: id_token,
      t: 'g',
    };

    const url = `https://${config.API_HOST}/dropbox_auth?username=${email}`;
    axios
      .get(url, { headers })
      .then(res => onSuccess(res))
      .catch(err => onFailure(err));
  }

  renderButton () {
    const { connected } = this.props

    if (connected) {
      return (
        <a className="inlineblock fontcolorgreen1 bgcolorgray textcenter borderradius3 connectbutton">
          CONNECTED
        </a>
      )
    } else {
      return (
        <a
          className="inlineblock fontwhitecolor bgcolorgreen textcenter borderradius3 connectbutton"
          onClick={() => this.authenticate()}
          style={{ cursor: 'pointer' }}
        >
          CONNECT
        </a>
      )
    }
  }

  render () {
    return (
      <div className="each_block paddingtb25">
        <div className="floatleft">
          <img src="/img/dropbox_drive.png" alt="google_drive" />
        </div>
        <div className="floatright">
          { this.renderButton() }
        </div>
        <div className="clearboth" />
      </div>
    );
  }
}

DropboxButton.propTypes = {
  email: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  authUrl: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};

DropboxButton.defaultProps = {
};

export default DropboxButton;
