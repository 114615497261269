const docImages = {
  '3ds': {
    x1: 'i_doc_3ds.imageset/023-document@x1.png',
    x2: 'i_doc_3ds.imageset/023-document@x2.png',
    x3: 'i_doc_3ds.imageset/023-document@x3.png',
  },
  aac: {
    x1: 'i_doc_aac.imageset/009-file-28.png',
    x2: 'i_doc_aac.imageset/009-file-28@x2.png',
    x3: 'i_doc_aac.imageset/009-file-28@x3.png',
  },
  ai: {
    x1: 'i_doc_ai.imageset/006-ai.png',
    x2: 'i_doc_ai.imageset/006-ai@x2.png',
    x3: 'i_doc_ai.imageset/006-ai@x3.png',
  },
  avi: {
    x1: 'i_doc_avi.imageset/014-file-23.png',
    x2: 'i_doc_avi.imageset/014-file-23@x2.png',
    x3: 'i_doc_avi.imageset/014-file-23@x3.png',
  },
  bmp: {
    x1: 'i_doc_bmp.imageset/027-file-11.png',
    x2: 'i_doc_bmp.imageset/027-file-11@x2.png',
    x3: 'i_doc_bmp.imageset/027-file-11@x3.png',
  },
  cad: {
    x1: 'i_doc_cad.imageset/015-file-22.png',
    x2: 'i_doc_cad.imageset/015-file-22@x2.png',
    x3: 'i_doc_cad.imageset/015-file-22@x3.png',
  },
  cdr: {
    x1: 'i_doc_cdr.imageset/026-file-12.png',
    x2: 'i_doc_cdr.imageset/026-file-12@x2.png',
    x3: 'i_doc_cdr.imageset/026-file-12@x3.png',
  },
  css: {
    x1: 'i_doc_css.imageset/035-file-5.png',
    x2: 'i_doc_css.imageset/035-file-5@x2.png',
    x3: 'i_doc_css.imageset/035-file-5@x3.png',
  },
  dat: {
    x1: 'i_doc_dat.imageset/012-file-25.png',
    x2: 'i_doc_dat.imageset/012-file-25@x2.png',
    x3: 'i_doc_dat.imageset/012-file-25@x3.png',
  },
  dll: {
    x1: 'i_doc_dll.imageset/022-file-15.png',
    x2: 'i_doc_dll.imageset/022-file-15@x2.png',
    x3: 'i_doc_dll.imageset/022-file-15@x3.png',
  },
  dmg: {
    x1: 'i_doc_dmg.imageset/010-file-27.png',
    x2: 'i_doc_dmg.imageset/010-file-27@x2.png',
    x3: 'i_doc_dmg.imageset/010-file-27@x3.png',
  },
  doc: {
    x1: 'i_doc_doc.imageset/007-doc.png',
    x2: 'i_doc_doc.imageset/007-doc@x2.png',
    x3: 'i_doc_doc.imageset/007-doc@x3.png',
  },
  docx: {
    x1: 'i_doc_doc.imageset/007-doc.png',
    x2: 'i_doc_doc.imageset/007-doc@x2.png',
    x3: 'i_doc_doc.imageset/007-doc@x3.png',
  },
  eps: {
    x1: 'i_doc_eps.imageset/017-file-20.png',
    x2: 'i_doc_eps.imageset/017-file-20@x2.png',
    x3: 'i_doc_eps.imageset/017-file-20@x3.png',
  },
  excel: {
    x1: 'i_doc_excel.imageset/xls.png',
    x2: 'i_doc_excel.imageset/xls@x2.png',
    x3: 'i_doc_excel.imageset/xls@x3.png',
  },
  fla: {
    x1: 'i_doc_fla.imageset/011-file-26.png',
    x2: 'i_doc_fla.imageset/011-file-26@x2.png',
    x3: 'i_doc_fla.imageset/011-file-26@x3.png',
  },
  flv: {
    x1: 'i_doc_flv.imageset/020-file-17.png',
    x2: 'i_doc_flv.imageset/020-file-17@x2.png',
    x3: 'i_doc_flv.imageset/020-file-17@x3.png',
  },
  gif: {
    x1: 'i_doc_gif.imageset/033-file-7.png',
    x2: 'i_doc_gif.imageset/033-file-7@x2.png',
    x3: 'i_doc_gif.imageset/033-file-7@x3.png',
  },
  html: {
    x1: 'i_doc_html.imageset/034-file-6.png',
    x2: 'i_doc_html.imageset/034-file-6@x2.png',
    x3: 'i_doc_html.imageset/034-file-6@x3.png',
  },
  indd: {
    x1: 'i_doc_indd.imageset/004-indd.png',
    x2: 'i_doc_indd.imageset/004-indd@x2.png',
    x3: 'i_doc_indd.imageset/004-indd@x3.png',
  },
  iso: {
    x1: 'i_doc_iso.imageset/024-file-14.png',
    x2: 'i_doc_iso.imageset/024-file-14@x2.png',
    x3: 'i_doc_iso.imageset/024-file-14@x3.png',
  },
  jpg: {
    x1: 'i_doc_jpg.imageset/040-file.png',
    x2: 'i_doc_jpg.imageset/040-file@x2.png',
    x3: 'i_doc_jpg.imageset/040-file@x3.png',
  },
  js: {
    x1: 'i_doc_js.imageset/005-js.png',
    x2: 'i_doc_js.imageset/005-js@x2.png',
    x3: 'i_doc_js.imageset/005-js@x3.png',
  },
  midi: {
    x1: 'i_doc_midi.imageset/013-file-24.png',
    x2: 'i_doc_midi.imageset/013-file-24@x2.png',
    x3: 'i_doc_midi.imageset/013-file-24@x3.png',
  },
  mov: {
    x1: 'i_doc_mov.imageset/018-file-19.png',
    x2: 'i_doc_mov.imageset/018-file-19@x2.png',
    x3: 'i_doc_mov.imageset/018-file-19@x3.png',
  },
  mp3: {
    x1: 'i_doc_mp3.imageset/031-file-9.png',
    x2: 'i_doc_mp3.imageset/031-file-9@x2.png',
    x3: 'i_doc_mp3.imageset/031-file-9@x3.png',
  },
  mpg: {
    x1: 'i_doc_mpg.imageset/019-file-18.png',
    x2: 'i_doc_mpg.imageset/019-file-18@x2.png',
    x3: 'i_doc_mpg.imageset/019-file-18@x3.png',
  },
  pdf: {
    x1: 'i_doc_pdf.imageset/028-pdf.png',
    x2: 'i_doc_pdf.imageset/028-pdf@x2.png',
    x3: 'i_doc_pdf.imageset/028-pdf@x3.png',
    png: 'pdf.png',
  },
  php: {
    x1: 'i_doc_php.imageset/038-file-2.png',
    x2: 'i_doc_php.imageset/038-file-2@x2.png',
    x3: 'i_doc_php.imageset/038-file-2@x3.png',
  },
  png: {
    x1: 'i_doc_png.imageset/002-png.png',
    x2: 'i_doc_php.imageset/038-png@x2.png',
    x3: 'i_doc_php.imageset/038-png@x3.png',
  },
  ppt: {
    x1: 'i_doc_ppt.imageset/029-ppt.png',
    x2: 'i_doc_ppt.imageset/029-ppt@x2.png',
    x3: 'i_doc_ppt.imageset/029-ppt@x3.png',
  },
  ps: {
    x1: 'i_doc_ps.imageset/021-file-16.png',
    x2: 'i_doc_ps.imageset/021-file-16@x2.png',
    x3: 'i_doc_ps.imageset/021-file-16@x3.png',
  },
  psd: {
    x1: 'i_doc_psd.imageset/032-file-8.png',
    x2: 'i_doc_psd.imageset/032-file-8@x2.png',
    x3: 'i_doc_psd.imageset/032-file-8@x3.png',
  },
  raw: {
    x1: 'i_doc_psd.imageset/003-raw.png',
    x2: 'i_doc_psd.imageset/003-raw@x2.png',
    x3: 'i_doc_psd.imageset/003-raw@x3.png',
  },
  sql: {
    x1: 'i_doc_sql.imageset/037-file-3.png',
    x2: 'i_doc_sql.imageset/037-file-3@x2.png',
    x3: 'i_doc_sql.imageset/037-file-3@x3.png',
  },
  svg: {
    x1: 'i_doc_svg.imageset/030-file-10.png',
    x2: 'i_doc_svg.imageset/030-file-10@x2.png',
    x3: 'i_doc_svg.imageset/030-file-10@x3.png',
  },
  tif: {
    x1: 'i_doc_tif.imageset/016-file-21.png',
    x2: 'i_doc_tif.imageset/016-file-21@x2.png',
    x3: 'i_doc_tif.imageset/016-file-21@x3.png',
  },
  txt: {
    x1: 'i_doc_txt.imageset/036-file-4.png',
    x2: 'i_doc_txt.imageset/036-file-4@x2.png',
    x3: 'i_doc_txt.imageset/036-file-4@x3.png',
  },
  wmv: {
    x1: 'i_doc_wmv.imageset/025-file-13.png',
    x2: 'i_doc_wmv.imageset/025-file-13@x2.png',
    x3: 'i_doc_wmv.imageset/025-file-13@x3.png',
  },
  xml: {
    x1: 'i_doc_xml.imageset/001-xml.png',
    x2: 'i_doc_xml.imageset/001-xml@x2.png',
    x3: 'i_doc_xml.imageset/001-xml@x3.png',
  },
  zip: {
    x1: 'i_doc_zip.imageset/039-file-1.png',
    x2: 'i_doc_zip.imageset/039-file-1@x2.png',
    x3: 'i_doc_zip.imageset/039-file-1@x3.png',
  },
};

export default docImages;
