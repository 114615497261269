import axios from 'axios'
import config from '../config'

const fetch = global.fetch;

function checkGoogleIsConnected (email) {
  const url = `https://${config.API_HOST}/google_connected`;
  
  // TODO: will use intercaptor for all axios call
  const id_token = localStorage.getItem('id_token') || '';
  const access_token = localStorage.getItem('access_token') || '';
  const headers = {
    at: access_token,
    it: id_token,
    t: 'g',
  };

  const options = {
    headers,
    params: {
      uname: email
    }
  }

  return axios
    .get(url, options);
}

function checkDropboxIsConnected (email) {
  const url = `https://${config.API_HOST}/dbox_connected`;
  
  // TODO: will use intercaptor for all axios call
  const id_token = localStorage.getItem('id_token') || '';
  const access_token = localStorage.getItem('access_token') || '';
  const headers = {
    at: access_token,
    it: id_token,
    t: 'g',
  };

  const options = {
    headers,
    params: {
      uname: email
    }
  }

  return axios
    .get(url, options);
}

function storeGoogleAuth (authResult) {
  const url = `https://${config.API_HOST}/storeGoogleauth`;
  const params = authResult.profileObj;

  // TODO: will use intercaptor for all axios call
  const id_token = localStorage.getItem('id_token') || '';
  const access_token = localStorage.getItem('access_token') || '';
  const headers = {
    at: access_token,
    it: id_token,
    t: 'g',
  };

  axios
    .get(url, { params, headers })
    .then(res => console.log(res))
    .catch(err => console.error(err));
}

function storeFacebookAuth(authResult) {
  const { fbID, imageUrl, email, name, givenName, familyName } = authResult;
  const url = `https://${config.API_HOST}/facebook_auth`;
  const params = {
    fbID,
    imageUrl,
    email,
    name,
    givenName,
    familyName,
  };

  // TODO: will use intercaptor for all axios call
  const id_token = localStorage.getItem('id_token') || '';
  const access_token = localStorage.getItem('access_token') || '';
  const headers = {
    at: access_token,
    it: id_token,
    t: 'g',
  };

  axios
    .get(url, params, { headers })
    .then(res => console.log(res))
    .catch(err => console.error(err));
}

export default {
  storeGoogleAuth,
  storeFacebookAuth,
  checkGoogleIsConnected,
  checkDropboxIsConnected,
};
