import {
  BUILD_FILTER,
  CLEAR_FILTER,
  REMOVE_FILTER,
  SET_FILTER,
} from '../constants/ActionConstants';

function buildFilter(data) {
  return { type: BUILD_FILTER, data };
}

function clearFilter() {
  return { type: CLEAR_FILTER };
}

function removeFilter(filter) {
  return { type: REMOVE_FILTER, filter };
}

function setFilter(filter) {
  return { type: SET_FILTER, filter };
}

const actions = {
  buildFilter,
  clearFilter,
  removeFilter,
  setFilter,
};

export default actions;
