// import history from '../history';
import auth0 from 'auth0-js';
import AUTH_CONFIG from './auth0-variables';
import BabuRouteConst from '../constants/BabuRouteConst';
import { DataActions, FilterActions } from '../actions';
import { replace } from 'react-router-redux'
import Api from './Api'

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: `https://${AUTH_CONFIG.domain}/userinfo`,
    responseType: 'token id_token',
    scope: 'openid'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.dispatch(replace(BabuRouteConst.home));
      } else if (err) {
        alert(`Error: ${err.error}. Check the console for further details.`);
        this.dispatch(replace(BabuRouteConst.home));
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the home route
    // history.replace(BabuRouteConst.home);
  }

  logout() {
    console.log('logout')

    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('email');

    // navigate to the home route
    this.dispatch(DataActions.clearData());
    this.dispatch(FilterActions.clearFilter());
    this.dispatch(replace(BabuRouteConst.index));
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  loginWithGoogle(authResult) {
    console.log(JSON.stringify(authResult));
    // // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.tokenObj.expires_at * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.tokenId);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('email', authResult.profileObj.email);

    Api.storeGoogleAuth(authResult);
  }

  loginWithFacebook(authResult) {
    console.log(JSON.stringify(authResult));
    // // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    // localStorage.setItem('id_token', authResult.machine_id);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('email', authResult.email);

    Api.storeFacebookAuth(authResult)
  }

  email() {
    return localStorage.getItem('email');
  }
}
