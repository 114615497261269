import React, { Component } from 'react';
import { connect } from 'react-redux';
import Section from './Section';

class FilterBar extends Component {
  renderSection() {
    const { Filters } = this.props;
    const keys = Object.keys(Filters);
    const tagsIndex = keys.indexOf('tags');

    if (tagsIndex !== -1) {
      keys.splice(tagsIndex, 1);
      keys.unshift('tags');
    }

    return keys.map((key) => {
      const filter = Filters[key];
      return filter && filter.buckets.length ?
        <Section key={key} filter={filter} dispatch={this.props.dispatch} /> : null;
    });
  }

  render() {
    return (
      <div className="width20 paddinglr20 floatleft rsp_width_sidebar">
        { this.renderSection() }
      </div>
    );
  }
}

function select(state) {
  return {
    Filters: state.Filters,
  };
}

export default connect(select)(FilterBar);
