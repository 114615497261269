import {
  SET_DROPBOX,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  SET_PROFILE,
} from '../constants/ActionConstants';

function setDropboxToken(token) {
  return { type: SET_DROPBOX, token };
}

function setProfile(profile) {
  return { type: SET_PROFILE, profile };
}

function login(user) {
  return { type: AUTH_LOGIN, user };
}

function logout() {
  return { type: AUTH_LOGOUT };
}

const actions = {
  setProfile,
  setDropboxToken,
  login,
  logout,
};

export default actions;
