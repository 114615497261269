import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OAuth from 'oauth-1.0a';
import hmacSHA1 from 'crypto-js/hmac-sha1';
import base64 from 'crypto-js/enc-base64';

const screen = global.screen;
const window = global.window;

const oauthConfig = {
  requestTokenUrl: 'https://api.twitter.com/oauth/request_token',
};

const oauth = OAuth({
  consumer: {
    key: 'k1hPVmAjKQVbPVSRSzvl5RL57',
    secret: 'XsZHDaZXP5GZoCGbN3OtP3L7YsqwnHe8hLbn3ACs5pZX5TV5NL',
  },
  signature_method: 'HMAC-SHA1',
  hash_function: (baseString, key) => hmacSHA1(baseString, key).toString(base64),
});

class TwitterButton extends Component {
  constructor(props) {
    super(props);

    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onButtonClick() {
    return this.getRequestToken();
  }

  getRequestToken() {
    const requestData = {
      url: oauthConfig.requestTokenUrl,
      method: 'POST',
    };
    const token = {
      key: '370773112-GmHxMAgYyLbNEtIKZeRNFsMKPR9EyMZeS9weJAEb',
      secret: 'LswwdoUaIvS8ltyTt5jkRh4J50vUPVVHtR2YPi5kE',
    };
    const headers = oauth.toHeader(oauth.authorize(requestData, token));
    console.log(headers);
    return window.fetch(oauthConfig.requestTokenUrl, {
      method: 'POST',
      headers,
    })
      .then(response => response.json())
      .then(data => this.openPopup(data.oauth_token))
      .catch(error => this.props.onFailure(error));
  }

  getOathToken(oAuthVerifier, oauthToken) {
    return window.fetch(`${this.props.loginUrl}?oauth_verifier=${oAuthVerifier}&oauth_token=${oauthToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => this.props.onSuccess(response))
      .catch(error => this.props.onFailure(error));
  }

  openPopup(token) {
    const w = this.props.dialogWidth;
    const h = this.props.dialogHeight;
    const left = (screen.width / 2) - (w / 2);
    const top = (screen.height / 2) - (h / 2);
    const popup = window.open(
      `https://api.twitter.com/oauth/authenticate?oauth_token=${token}`,
      '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    this.polling(popup);
  }

  polling(popup) {
    const polling = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
        this.props.onFailure(new Error('Popup has been closed by user'));
      }

      const closeDialog = () => {
        clearInterval(polling);
        popup.close();
      };

      try {
        if (!popup.location.hostname.includes('api.twitter.com') &&
          !popup.location.hostname === '') {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);

            const oauthToken = query.get('oauth_token');
            const oauthVerifier = query.get('oauth_verifier');

            closeDialog();
            return this.getOathToken(oauthVerifier, oauthToken);
          } else {
            closeDialog();
            return this.props.onFailure(new Error(
              'OAuth redirect has occurred but no query or hash parameters were found. ' +
              'They were either not set during the redirect, or were removed—typically by a ' +
              'routing library—before Twitter react component could read it.'
            ));
          }
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in IE.
      }
    }, 500);
  }

  render() {
    return (
      <a className="paddinglr10" onClick={() => this.onButtonClick()}>
        <img src="img/twitter_circle.png" alt="twitter" />
      </a>
    );
  }
}

TwitterButton.propTypes = {
  loginUrl: PropTypes.string.isRequired,
  dialogWidth: PropTypes.number,
  dialogHeight: PropTypes.number,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};

TwitterButton.defaultProps = {
  dialogWidth: 100,
  dialogHeight: 100,
};

export default TwitterButton;
