import { DocImageConst } from '../constants';

function parseQueryString(str) {
  const ret = Object.create(null);

  if (typeof str !== 'string') {
    return ret;
  }

  str = str.trim().replace(/^(\?|#|&)/, '');

  if (!str) {
    return ret;
  }

  str.split('&').forEach((param) => {
    const parts = param.replace(/\+/g, ' ').split('=');
    // Firefox (pre 40) decodes `%3D` to `=`
    // https://github.com/sindresorhus/query-string/pull/37
    let key = parts.shift();
    let val = parts.length > 0 ? parts.join('=') : undefined;

    key = decodeURIComponent(key);

    // missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    val = val === undefined ? null : decodeURIComponent(val);

    if (ret[key] === undefined) {
      ret[key] = val;
    } else if (Array.isArray(ret[key])) {
      ret[key].push(val);
    } else {
      ret[key] = [ret[key], val];
    }
  });

  return ret;
}

function imageSrc(extension, hint) {
  if (!extension) {
    return '';
  }

  if (DocImageConst[extension]) {
    if (DocImageConst[extension].png) {
      return `/img/${DocImageConst[extension].png}`;
    }
    return `/img/assets/${DocImageConst[extension].x3}`;
  }

  //switch (hint) {
  //  case 'list':
  //    return `https://dummyimage.com/79x80/61c71e/fff.png&text=${extension.toUpperCase()}`;
  //  case 'grid':
  //    return `https://dummyimage.com/170x190/61c71e/fff.png&text=${extension.toUpperCase()}`;
  //  default:
  //    return `https://dummyimage.com/150x172/61c71e/fff.png&text=${extension.toUpperCase()}`;
  //}
}

export default {
  parseQueryString,
  imageSrc,
};
