import React from 'react';

const Section = () => (
  <div className="row bgcolorgreen paddingtb15">
    <div className="width70 marginauto rsp_width_green">
    <div className="col-sm-4 inlineblock textcenter padding10">
      <img src="img/badu_securse.png" className="img-unblock-responsive" alt="securse" />
      <h4 className="fontwhitecolor paddingtb10">Babu Secures your Documents</h4>
      <p className="width65 marginauto fontwhitecolor fontsize1p1"></p>
    </div>
    <div className="col-sm-4 inlineblock textcenter padding10">
        <img src="img/badu_document.png" className="img-unblock-responsive" alt="document" />
        <h4 className="fontwhitecolor paddingtb10">Babu Categorizes your Documents</h4>
        <p className="width65 marginauto fontwhitecolor fontsize1p1"></p>
      </div>
      <div className="col-sm-4 inlineblock textcenter padding10">
        <img src="img/badu_assist.png" className="img-unblock-responsive" alt="assist" />
        <h4 className="fontwhitecolor paddingtb10">Babu Assists in Clerical Tasks</h4>
        <p className="width65 marginauto fontwhitecolor fontsize1p1"></p>
      </div>
      <div className="clearboth"></div>
    </div>
  </div>
);

export default Section;
