import {
  createStore, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
// import { createLogger } from 'redux-logger';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
// import { persistStore, persistReducer } from 'redux-persist-immutable';
import storage from 'redux-persist/lib/storage';
import promiseMiddleware from './libs/promiseMiddleware';
import reducer from './reducers';
import {
  searchDataSaga,
  filterSagas as FilterSagas,
} from './sagas';

const window = global.window;

// const logger = createLogger({ log: 'info'});

export const history = createHistory();

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// const enhancers = [];
const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
  promiseMiddleware,
  thunk,
  // logger,
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['Nav'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));
const persistor = persistStore(store);

// then run the saga
sagaMiddleware.run(searchDataSaga);
sagaMiddleware.run(FilterSagas.clearFilterSaga);
sagaMiddleware.run(FilterSagas.removeFilterSaga);
sagaMiddleware.run(FilterSagas.setFilterSaga);

export default () => ({ store, persistor });
