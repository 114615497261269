export const ELASTIC_SEARCH = 'ELASTIC_SEARCH';
export const ELASTIC_SEARCH_SUCCESS = 'ELASTIC_SEARCH_SUCCESS';
export const ELASTIC_SEARCH_FAIL = 'ELASTIC_SEARCH_FAIL';
export const ELASTIC_SEARCH_PAGE = 'ELASTIC_SEARCH_PAGE';
export const ELASTIC_SEARCH_PAGE_SUCCESS = 'ELASTIC_SEARCH_PAGE_SUCCESS';
export const ELASTIC_SEARCH_PAGE_FAIL = 'ELASTIC_SEARCH_PAGE_FAIL';
export const FILTER_CHANGED = 'FILTER_CHANGED';
export const CLEAR_DATA = 'CLEAR_DATA';

export const SHOW_SIDE_BAR = 'SHOW_SIDE_BAR';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_PROFILE = 'SET_PROFILE';
export const SET_DROPBOX = 'SET_DROPBOX';

export const BUILD_FILTER = 'BUILD_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_INPUT = 'SET_FILTER_INPUT';
export const SET_FILTER_TOGGLE = 'SET_FILTER_TOGGLE';
export const SET_FILTER_CHECK = 'SET_FILTER_CHECK';

export const FIRST_PAGE = 'FIRST_PAGE';
export const PREV_PAGE = 'PREV_PAGE';
export const NEXT_PAGE = 'NEXT_PAGE';
export const GOTO_PAGE = 'GOTO_PAGE';
export const LAST_PAGE = 'LAST_PAGE';

export const SET_ERROR = 'SET_ERROR';
