import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BarLoader } from 'react-spinners';
import $ from 'jquery';
import {
  SearchBar,
  FilterBar,
  FilterBox,
  ItemListCard,
  ItemGridCard,
  ItemDetailCard,
  Pagination,
} from '../components/Home';
import { DataActions, FilterActions } from '../actions';
import AppConst from '../constants/AppConstants';

const window = global.window;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayOption: 'list',
      loading: false,
      error: '',
      filterChanged: '',
      lastIndex: -1,   // data's last index be loaded
    };

    this.renderSearchBar = this.renderSearchBar.bind(this);
    this.renderSearchResult = this.renderSearchResult.bind(this);
    this.renderFilterBar = this.renderFilterBar.bind(this);
    this.toggleDisplayOption = this.toggleDisplayOption.bind(this);
    this.onSubmitQuery = this.onSubmitQuery.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.onRemoveFilter = this.onRemoveFilter.bind(this);
    this.onNextPage = this.onNextPage.bind(this);
    this.onPrevPage = this.onPrevPage.bind(this);
    this.onGoToPage = this.onGoToPage.bind(this);
    this.onFirstPage = this.onFirstPage.bind(this);
    this.onLastPage = this.onLastPage.bind(this);
  }

  componentDidMount() {
    const { searchResult, query } = this.props.Data;

    window.scrollTo(0, 0);

    this.searchData(query);

    setTimeout(() => {
      $('.loader').hide();
      $('.servicebtn').fadeIn('slow');
    }, 7000);
  }

  componentWillReceiveProps(nextProps) {
    const { loading, error, filterChanged, query } = nextProps.Data;

    this.setState({
      loading,
      error,
    });

    if (error) {
      setTimeout(() => {
        this.setState({ error: '' });
      }, 2000);
    }

    if (this.state.filterChanged !== filterChanged) {
      this.setState({ filterChanged });
      this.searchData(query);
    }

    setTimeout(() => {
      $('.loader').hide();
      $('.servicebtn').fadeIn('slow');
    }, 7000);
  }

  onSubmitQuery(query) {
    this.searchData(query);
  }

  onClearFilters() {
    this.props.dispatch(FilterActions.clearFilter());
  }

  onRemoveFilter(filter) {
    this.props.dispatch(FilterActions.removeFilter(filter));
  }

  onPrevPage(e) {
    e.preventDefault();
    const { pages } = this.props.Data;
    if (pages.current > 0) {
      this.searchPage(pages.current - 1);
    }
  }

  onNextPage(e) {
    e.preventDefault();
    const { pages } = this.props.Data;
    if ((pages.current + 1) < pages.count) {
      this.searchPage(pages.current + 1);
    }
  }

  onGoToPage(page) {
    const { pages } = this.props.Data;
    if (pages.count > 0 && pages.current !== page) {
      this.searchPage(page);
    }
  }

  onFirstPage(e) {
    e.preventDefault();
    const { pages } = this.props.Data;
    if (pages.count > 0  && pages.current !== 0) {
      this.searchPage(0);
    }
  }

  onLastPage(e) {
    e.preventDefault();
    const { pages } = this.props.Data;
    if (pages.count > 0 && pages.current !== pages.count - 1) {
      this.searchPage(pages.count - 1);
    }
  }

  getCheckedFilters() {
    const { Filters } = this.props;
    const checkedFilters = [];

    Object.keys(Filters).forEach((key) => {
      Filters[key].buckets.forEach((bucket) => {
        if (bucket.checked) {
          const filter = {};
          filter[key] = bucket.key;
          checkedFilters.push(filter);
        }
      });
    });

    return checkedFilters;
  }

  searchData(query) {
    const { User, Data } = this.props;

    if (Data.loading) {
      return;
    }

    const email = User.profile.email ? User.profile.email : window.auth.email();

    const payload = {
      type: ['query'],
      words: query || '*',
      sort: [Data.sortOption],
      from: '0',
      size: AppConst.ItemsPerPage.toString(),
      filters: this.getCheckedFilters(),
      index: email,
    };

    this.props.dispatch(DataActions.searchElastic(payload));

    window.scrollTo(0, 0);
  }

  searchPage(page) {
    const { Data } = this.props;
    const { payload, pages, loading } = Data;

    if (loading) {
      return;
    }

    const start = page * pages.size;

    payload.from = start.toString();
    payload.size = pages.size.toString();

    this.props.dispatch(DataActions.searchElasticPage(payload, page));

    window.scrollTo(0, 0);
  }

  toggleDisplayOption() {
    const { displayOption } = this.state;

    if (displayOption === 'grid') {
      this.setState({ displayOption: 'list' });
    } else {
      this.setState({ displayOption: 'grid' });
    }
  }

  renderHeader() {
    return (
      <div>
        <div className="height25">&nbsp;</div>
        <h3 className="fontcolorblue1 textcenter">Let babu help find your stuff</h3>
        <p className="fontcolorblue2 fontsize1p1 textcenter rsp_header_description">You can also type questions like “What is my social security number?”</p>
      </div>
    )
  }

  renderSearchBar() {
    const { query, loading } = this.props.Data;

    return (
      <SearchBar
        query={query}
        loading={loading}
        onSubmit={this.onSubmitQuery}
        toggleDisplayOption={this.toggleDisplayOption}
        displayOption={this.state.displayOption}
      />
    );
  }

  renderSearchResultByList(items) {
    return items.map((item, index) => (
      <div className="content_block paddingtb15" key={item._id}>
        <ItemListCard item={item} index={index} />
      </div>
    ));
  }

  renderSearchResultByGrid(items) {
    return (
      <div className="_grid-row-one" style={{ gridTemplateColumns: 'repeat(auto-fit, 220px)' }}>
        {
          items.map((item, index) => <ItemGridCard item={item} index={index} key={item._id} />)
        }
      </div>
    );
  }

  renderSearchResultDetail(index) {
    const { searchResult, count } = this.props.Data;

    if (searchResult === null) {
      return null;
    }

    if (index < count) {
      const item = searchResult.results.hits.hits[index];

      if (!item) {
        return null;
      }

      return (
        <div className="_grid-one _margin-bottom" key={item}>
          <ItemDetailCard item={item} />
        </div>
      );
    }

    return null;
  }

  renderSearchResult() {
    const { index } = this.props.match.params;
    const { displayOption, loading, error } = this.state;
    const { results } = this.props.Data;

    if (!results) {
      return null;
    }

    if (error) {
      return JSON.stringify(error);
    }

    if (loading) {
      return (
        <BarLoader
          color={'#123abc'}
          loading={loading}
        />
      );
    }

    if ( results.length === 0) {
      return null;
    }

    if (index) {
      window.scrollTo(0, 0);
      return this.renderSearchResultDetail(index);
    }

    return displayOption === 'list' ?
      this.renderSearchResultByList(results) :
      this.renderSearchResultByGrid(results);
  }

  renderFilterBar() {
    const checkedFilters = this.getCheckedFilters();
    return <FilterBar filters={checkedFilters} />;
  }

  renderFilterBox() {
    const { loading, total, results, pages } = this.props.Data;

    const checkedFilters = this.getCheckedFilters().map((item) => {
      const key = Object.keys(item)[0];
      return {
        title: this.props.Filters[key].title,
        key,
        value: item[key],
      };
    });

    return (
      <FilterBox
        loading={loading}
        total={total}
        count={results.length}
        selected={checkedFilters}
        pages={pages}
        onRemoveFilter={this.onRemoveFilter}
      />
    );
  }

  renderPagination() {
    const { index } = this.props.match.params;
    const { pages, loading } = this.props.Data;

    if (index) {
      return null;
    }

    if (loading) {
      return null;
    }

    return (
      <Pagination
        pages={pages}
        onPrevPage={this.onPrevPage}
        onNextPage={this.onNextPage}
        onGotoPage={this.onGoToPage}
        onFirstPage={this.onFirstPage}
        onLastPage={this.onLastPage}
      />
    );
  }

  render() {
    return (
      <div className="container">
        { this.renderHeader() }
        { this.renderSearchBar() }
        <div className="width90 marginauto rsp_width_total">
          { this.renderFilterBar() }
          <div className="width80 paddinglr25 paddingtop10 floatright sideline rsp_width_center">
            { this.renderSearchResult() }
            { this.renderPagination() }
          </div>
        </div>
      </div>
    );
  }
}

function select(state) {
  return {
    Nav: state.Nav,
    Data: state.Data,
    Filters: state.Filters,
    User: state.User,
  };
}

export default connect(select)(Home);
