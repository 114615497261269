import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import axios from 'axios';

class GoogleButton extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.state = {
      disabled: true,
    };
  }
  componentDidMount() {
  }
  signIn(e) {
    const { email, onSuccess, onFailure } = this.props

    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }

    const id_token = localStorage.getItem('id_token') || '';
    const access_token = localStorage.getItem('access_token') || '';
    const headers = {
      at: access_token,
      it: id_token,
      t: 'g',
    };

    const url = `https://${config.API_HOST}/google_auth?username=${email}`;
    axios
      .get(url, { headers })
      .then(res => onSuccess(res))
      .catch(err => onFailure(err));
    // window.location = url;
  }

  authenticate() {
    const { authUrl } = this.props

    window.open(authUrl)
  }

  _handleSigninSuccess(res) {
    this.props.onSuccess(res);
  }

  renderButton () {
    const { connected } = this.props

    if (connected) {
      return (
        <a className="inlineblock fontcolorgreen1 bgcolorgray textcenter borderradius3 connectbutton">
          CONNECTED
        </a>
      )
    } else {
      return (
        <a
          className="inlineblock fontwhitecolor bgcolorgreen textcenter borderradius3 connectbutton"
          onClick={() => this.authenticate()}
          style={{ cursor: 'pointer' }}
        >
          CONNECT
        </a>
      )
    }
  }

  render() {
    return (
      <div className="each_block paddingtb25">
        <div className="floatleft">
          <img src="/img/google_drive.png" alt="google_drive" />
        </div>
        <div className="floatright">
          { this.renderButton() }
        </div>
        <div className="clearboth" />
      </div>
    );
  }
}

GoogleButton.propTypes = {
  email: PropTypes.string.isRequired,
  authUrl: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};

GoogleButton.defaultProps = {
  onRequest: () => {},
};

export default GoogleButton;
