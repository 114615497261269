import {
  ELASTIC_SEARCH,
  ELASTIC_SEARCH_SUCCESS,
  ELASTIC_SEARCH_FAIL,
  ELASTIC_SEARCH_PAGE,
  ELASTIC_SEARCH_PAGE_SUCCESS,
  ELASTIC_SEARCH_PAGE_FAIL,
  FILTER_CHANGED,
  PREV_PAGE,
  NEXT_PAGE,
  GOTO_PAGE,
  FIRST_PAGE,
  LAST_PAGE,
  CLEAR_DATA,
  SET_ERROR,
} from '../constants/ActionConstants';
import AppConst from '../constants/AppConstants';

// const assign = Object.assign || require('object.assign');
const initialQuery = '';
const initialPages = {
  size: AppConst.ItemsPerPage, // count of item per page
  count: 0, // count of the pages
  items: [], // array of the items are shown in the page
  current: -1, // index from 0,
  error: '',
};

const initialState = {
  query: initialQuery,
  sortOption: 'Date',
  searchResult: null,
  count: 0,
  error: '',
  loading: false,
  filterChanged: '',
  pages: initialPages,
  results: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CLEAR_DATA:
      return initialState;

    case ELASTIC_SEARCH:
      return Object.assign({}, state, {
        loading: true,
        count: 0,
        pages: initialPages,
        payload: action.payload,
      });

    case ELASTIC_SEARCH_SUCCESS: {
      const total = action.data.results.hits.total;
      const count = Math.ceil(total / AppConst.ItemsPerPage);

      const pages = {
        size: AppConst.ItemsPerPage,
        count,
        items: [],
        current: count > 0 ? 0 : -1,
      };

      // merge data
      return Object.assign({}, state, {
        loading: false,
        searchResult: action.data,
        results: action.data.results.hits.hits,
        total,
        pages,
        error: '',
      });
    }

    case ELASTIC_SEARCH_FAIL:
      return Object.assign({}, state, {
        loading: false,
        error: action.err.message || 'unexpected error',
        searchResult: null,
        count: 0,
      });

    case ELASTIC_SEARCH_PAGE:
      return Object.assign({}, state, {
        loading: true,
        payload: action.payload,
      });

    case ELASTIC_SEARCH_PAGE_SUCCESS: {
      const pages = Object.assign({}, state.pages);

      pages.current = action.page;

      return Object.assign({}, state, {
        loading: false,
        searchResult: action.data,
        results: action.data.results.hits.hits,
        error: '',
        pages,
      });
    }

    case ELASTIC_SEARCH_PAGE_FAIL:
      return Object.assign({}, state, {
        loading: false,
        error: JSON.stringify(action.err),
      });

    case FILTER_CHANGED:
      return Object.assign({}, state, {
        filterChanged: action.changed,
      });

    case PREV_PAGE:
      if (state.pages.current > 0) {
        const pages = Object.assign({}, state.pages);
        pages.current -= 1;
        return Object.assign({}, state, { pages });
      }

      return state;

    case NEXT_PAGE:
      if (state.pages.current < state.pages.count - 1) {
        const pages = Object.assign({}, state.pages);
        pages.current += 1;
        return Object.assign({}, state, { pages });
      }

      return state;

    case GOTO_PAGE:
      if (action.page > -1 && action.page < state.pages.count) {
        if (action.page !== state.pages.current) {
          const pages = Object.assign({}, state.pages, { current: action.page });
          return Object.assign({}, state, { pages });
        }
      }

      return state;

    case FIRST_PAGE:
      if (state.pages.count > 0 && state.pages.current !== 0) {
        if (action.page !== state.pages.current) {
          const pages = Object.assign({}, state.pages, { current: 0 });
          return Object.assign({}, state, { pages });
        }
      }

      return state;

    case LAST_PAGE:
      if (state.pages.count > 0 && state.pages.current !== state.pages.count - 1) {
        const pages = Object.assign({}, state.pages, { current: action.page });
        return Object.assign({}, state, { pages });
      }

      return state;

    case SET_ERROR:
      return Object.assign({}, state, { err: action.err });

    default:
      return state;
  }
};
