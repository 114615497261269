import React from 'react';

const Section = props => (
  <div className="row paddingtb25">
    <div className="width60 marginauto paddingtb30 rsp_width_chat">
      <div className="width49 padding_description paddinglr25 floatright rsp_width_chat_description">
        <h2 className="fontcolorblue1">Can't remember or find something?</h2>
        <div className="fontsize1p2 fontcolorgray3"></div>
      </div>
      <div className="width49 relativeblock paddinglr25 floatleft rsp_width_chat_content">
        <div className="chat_block width95 marginauto paddingtb20">
          <div className="chat_padding">
            <div className="chat_name textright fontcolorgray3">me</div>
            <div className="floatright chat_content inlineblock textright padding10 bgcolorgreen fontwhitecolor borderradius5 relativeblock">What is my Hertz <br/>Gold Number?
              <div className="absoluteblock chat_direct_right"><img src="img/chat_direct_right.png" alt="direct" /></div>
            </div>
            <div className="clearboth"></div>
          </div>
          <div className="chat_padding">
            <div className="chat_name textleft fontcolorgray3">Babu</div>
            <div className="floatleft chat_content inlineblock textleft bordercolorgray padding10 bgcolorgray borderradius5 relativeblock">Here is your Golf Hertz <br/>Number: 24458003
              <div className="absoluteblock chat_direct_left"><img src="img/chat_direct_left.png" alt="direct" /></div>
            </div>
            <div className="clearboth"></div>
          </div>
        </div>
        <div className="absoluteblock chat_box_shadow">&nbsp;</div>
      </div>
      <div className="clearboth"></div>
    </div>
    <div className="width60 marginauto paddingtb30 rsp_width_chat">
      <div className="col-sm-6 padding_description paddinglr25 rsp_width_chat_description">
        <h2 className="fontcolorblue1 textright rsp_textalign_left">Need to find and gather documents?</h2>
        <div className="fontsize1p2 fontcolorgray3 textright width95 floatright rsp_textalign_left"></div>
        <div className="clearboth"></div>
      </div>
      <div className="col-sm-6 relativeblock paddinglr25 rsp_width_chat_content">
        <div className="chat_block width95 marginauto paddingtb20">
          <div className="chat_padding">
            <div className="chat_name textright fontcolorgray3">me</div>
            <div className="floatright chat_content inlineblock textright padding10 bgcolorgreen fontwhitecolor borderradius5 relativeblock">Find all Georgia Power Bills from 2018
              <div className="absoluteblock chat_direct_right"><img src="img/chat_direct_right.png" alt="direct" /></div>
            </div>
            <div className="clearboth"></div>
          </div>
          <div className="chat_padding">
            <div className="chat_name textleft fontcolorgray3">Babu</div>
            <div className="floatleft chat_content inlineblock textleft bordercolorgray padding10 bgcolorgray borderradius5 relativeblock">Found 12 documents <br></br> <b>Options:</b>  Email them or Put in a folder
              <div className="absoluteblock chat_direct_left"><img src="img/chat_direct_left.png" alt="direct" /></div>
            </div>
            <div className="clearboth"></div>
          </div>
          <div className="chat_padding">
            <div className="chat_name textright fontcolorgray3">me</div>
            <div className="floatright chat_content inlineblock textright padding10 bgcolorgreen fontwhitecolor borderradius5 relativeblock">Folder
              <div className="absoluteblock chat_direct_right"><img src="img/chat_direct_right.png" alt="direct" /></div>
            </div>
            <div className="clearboth"></div>
           </div>
    <div className="chat_padding">
    <div className="chat_name textleft fontcolorgray3">Babu</div>
    <div className="floatleft chat_content inlineblock textleft bordercolorgray padding10 bgcolorgray borderradius5 relativeblock">Created Folder named: "Georgia Power 2018" in Dropbox
<div className="absoluteblock chat_direct_left"><img src="img/chat_direct_left.png" alt="direct" /></div>
    </div>
    <div className="clearboth"></div>
    </div>
        </div>
        <div className="absoluteblock chat_box_shadow">&nbsp;</div>
      </div>
      <div className="clearboth"></div>
    </div>
    <div className="width60 marginauto  paddingtb30 rsp_width_chat">
      <div className="width49 padding_description1 paddinglr25 floatright rsp_width_chat_description">
        <h2 className="fontcolorblue1">Need to do your taxes?</h2>
        <div className="fontsize1p2 fontcolorgray3"></div>
      </div>
      <div className="width49 relativeblock floatleft paddinglr25 rsp_width_chat_content">
        <div className="chat_block width95 marginauto paddingtb20">
          <div className="chat_padding">
            <div className="chat_name textright fontcolorgray3">me</div>
            <div className="floatright chat_content inlineblock textright padding10 bgcolorgreen fontwhitecolor borderradius5 relativeblock">Gather all my tax documents <br/>and receipts from 2017
              <div className="absoluteblock chat_direct_right"><img src="img/chat_direct_right.png" alt="direct" /></div>
            </div>
            <div className="clearboth"></div>
          </div>
    <div className="chat_padding">
    <div className="chat_name textleft fontcolorgray3">Babu</div>
    <div className="floatleft chat_content inlineblock textleft bordercolorgray padding10 bgcolorgray borderradius5 relativeblock">Found 26 documents <br></br> <b>Options:</b>  Email them or Put in a folder
<div className="absoluteblock chat_direct_left"><img src="img/chat_direct_left.png" alt="direct" /></div>
    </div>
    <div className="clearboth"></div>
    </div>
    <div className="chat_padding">
    <div className="chat_name textright fontcolorgray3">me</div>
    <div className="floatright chat_content inlineblock textright padding10 bgcolorgreen fontwhitecolor borderradius5 relativeblock">Email to account@firm.com
    <div className="absoluteblock chat_direct_right"><img src="img/chat_direct_right.png" alt="direct" /></div>
    </div>
    <div className="clearboth"></div>
    </div>
          <div className="chat_padding">
            <div className="chat_name textleft fontcolorgray3">Babu</div>
            <div className="floatleft chat_content inlineblock textleft bordercolorgray padding10 bgcolorgray borderradius5 relativeblock">26 Documents of type reciepts and taxes from 2017 sent to accountant@firm.com
              <div className="absoluteblock chat_direct_left"><img src="img/chat_direct_left.png" alt="direct" /></div>
            </div>
            <div className="clearboth"></div>
          </div>
        </div>
        <div className="absoluteblock chat_box_shadow">&nbsp;</div>
      </div>
      <div className="clearboth"></div>
    </div>
  </div>
);

export default Section;
