import { put, takeLatest } from 'redux-saga/effects';
import {
  CLEAR_FILTER,
  REMOVE_FILTER,
  SET_FILTER,
  FILTER_CHANGED,
} from '../constants/ActionConstants';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* filterChanged() {
  yield put({ type: FILTER_CHANGED, changed: Math.random() });
}

function* clearFilterSaga() {
  yield takeLatest(CLEAR_FILTER, filterChanged);
}

function* removeFilterSaga() {
  yield takeLatest(REMOVE_FILTER, filterChanged);
}

function* setFilterSaga() {
  yield takeLatest(SET_FILTER, filterChanged);
}

export default {
  clearFilterSaga,
  removeFilterSaga,
  setFilterSaga,
};
