import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  FirstSection,
  IntroSection,
  ThirdSection,
  Section4,
  Footer,
  Navbar,
} from '../components/Landing';
import { BabuRouteConst } from '../constants';

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onNavClick = this.onNavClick.bind(this);
  }

  onNavClick(link) {
    switch (link) {
      case 'login':
        this.props.dispatch(push(BabuRouteConst.login));
        break;
      default:
    }
  }

  render() {
    return (
      <div className="container">
        <Navbar handleClickLogin={() => this.onNavClick('login')}/>
        <FirstSection/>
        <IntroSection/>
        <div className="height25" />
        <ThirdSection/>
        <Section4/>
        <div className="margintop10">&nbsp;</div>
        <Footer/>
      </div>
    );
  }
}

function select(state) {
  return {
    Router: state.Router,
  };
}

export default connect(select)(Landing);
