import React from 'react';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const onClick = () => {
    if (typeof props.onClick !== 'undefined') {
      props.onClick();
    }
  };

  return (
    <a className="_logo" onClick={onClick}>
      <h1 className="_logo">
        B<i>a</i>b<o>u</o>
      </h1>
    </a>
  );
};

Logo.PropTypes = {
  onClick: PropTypes.func,
};

export default Logo;
