import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import {
  Home,
  Account,
  Contact,
} from '../views';
import { HeaderMenu } from '../components';
import { BabuRouteConst, AppConst } from '../constants';

class Drawer extends Component {
  constructor(props) {
    super(props);

    this.menus = [{
      key: AppConst.menus.home,
      title: 'Home',
    }, {
      key: AppConst.menus.apps,
      title: 'Connect Apps',
    }, {
      key: AppConst.menus.contact,
      title: 'Contacts',
    }];

    this.onClickMenu = this.onClickMenu.bind(this);
  }

  onClickMenu(menu) {
    const { history } = this.props;

    switch (menu) {
      case AppConst.menus.home:
        history.push(BabuRouteConst.home);
        return;
      case AppConst.menus.apps:
        history.push(BabuRouteConst.homeApps);
        return;
      case AppConst.menus.contact:
        history.push(BabuRouteConst.homeContact);
        return;
      case AppConst.menus.logout:
        window.auth.logout();
        history.push(BabuRouteConst.index);
        return;
      default:
    }
  }

  render() {
    const { match } = this.props;
    const selectedMenu = match.params.menu || AppConst.menus.home;

    return (
      <div className="container">
        <HeaderMenu menus={this.menus} selectedMenu={selectedMenu} onClick={this.onClickMenu} />
        <Switch>
          <Route exact path={BabuRouteConst.home} name="Home" component={Home} />
          <Route path={BabuRouteConst.homeApps} name="Apps" component={Account} />
          <Route path={BabuRouteConst.homeContact} name="Contact" component={Contact} />
        </Switch>
      </div>
    );
  }
}

Drawer.propTypes = {
  match: PropTypes.object.isRequired,
};

function select(state) {
  return {
    Data: state.Data,
    User: state.User,
  };
}

export default connect(select)(Drawer);
