import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      query: nextProps.query,
      loading: nextProps.loading,
    });
  }

  handleChange(e) {
    this.setState({
      query: e.target.value,
    });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.props.onSubmit(this.state.query);
    }
  }

  handleClick() {
    this.props.onSubmit(this.state.query);
  }

  renderDisplayOption () {
    const { displayOption, toggleDisplayOption } = this.props;

    return (
      <div className="absoluteblock gridview_block">
        <span className="fontcolorgray1">
          {displayOption === 'grid' ?  'GRID VIEW' : 'LIST VIEW'}
        </span>
        <a onClick={() => toggleDisplayOption()}>
          {
            displayOption === 'grid' ?
              <img src="img/gridview.png" className="gridview paddingleft15" alt="gridview" /> :
              <img src="img/listview.png" className="gridview paddingleft15" alt="listview" />
          }
        </a>
      </div>
    )
  }

  render() {
    const { query } = this.state;

    return (
      <div className="paddingtb25 textcenter width90 marginauto relativeblock">
        <input type="text"
               name="search"
               className="search"
               placeholder="Search"
               value={query}
               onChange={this.handleChange}
               onKeyPress={this.handleKeyPress}
        />
        <img src="img/searchbtn.png" className="searchbtn" alt="search" onClick={this.handleClick}/>
        {/*{ this.renderDisplayOption() }*/}
      </div>
    );
  }
}

SearchBar.propTypes = {
  query: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  displayOption: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleDisplayOption: PropTypes.func.isRequired,
};

export default SearchBar;
