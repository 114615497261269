import {
  BUILD_FILTER,
  CLEAR_FILTER,
  REMOVE_FILTER,
  SET_FILTER,
} from '../constants/ActionConstants';
import { isNumber } from 'util';

const initialState = {
};

function buildFilter(state, data) {
  const { aggregations } = data.results;
  const availableFilters = aggregations;

  if (!aggregations) {
    return state;
  }

  for(let key in availableFilters) {
    availableFilters[key].key = key;
    availableFilters[key].input = state[key] ? state[key].input : '';
    availableFilters[key].title = key;
    if (key === 'range') {
      availableFilters[key].type = 'date';
    } else {
      availableFilters[key].type = 'list';
    }

    // set checked fields
    const { buckets } = availableFilters[key];

    if (buckets && buckets.length) {
      if (state[key]) {
        for(let index in buckets) {
          const bucket = buckets[index];
          bucket.checked = false;
          for (let i = 0; i < state[key].buckets.length; i += 1) {
            if (state[key].buckets[i].key === bucket.key) {
              bucket.checked = state[key].buckets[i].checked;
              break;
            }
          }
        }
      } else {
        availableFilters[key].buckets.forEach(bucket => bucket.checked = false);
      }
    }

    // sort buckets
    availableFilters[key].buckets.sort((a, b) => {
      if (a.doc_count < b.doc_count) {
        return -1;
      }
      if (a.doc_count > b.doc_count) {
        return 1;
      }

      return a.key.localeCompare(b.key);
    });
  }

  return availableFilters;
}

function clearFilter(state) {
  const newState = Object.assign({}, state);

  Object.keys(newState).forEach(key => newState[key].buckets.forEach(bucket => bucket.checked = false));

  return newState;
}

function removeFilter(state, filter) {
  const newState = Object.assign({}, state);

  switch (newState[filter.key].type) {
    case 'list':
      newState[filter.key].buckets.forEach((bucket) => {
        if (bucket.key === filter.value) {
          bucket.checked = false;
        }
      });
      break;
    default:
  }

  return newState;
}

function setFilter(state, filter) {
  const newState = Object.assign({}, state);

  newState[filter.key] = filter;

  return newState;
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case BUILD_FILTER:
      return buildFilter(state, action.data);

    case CLEAR_FILTER:
      return clearFilter(state);

    case REMOVE_FILTER:
      return removeFilter(state, action.filter);

    case SET_FILTER:
      return setFilter(state, action.filter);

    default:
      return state;
  }
};
