import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  Landing,
  Login,
  Callback,
  CallbackGoogle,
  CallbackDropbox
} from './views';
import {
  Drawer,
} from './containers';
import BabuRouteConst from './constants/BabuRouteConst';
import { history } from './Store';
import Auth from './services/Auth';

const auth = new Auth()

// set global
window.auth = auth

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    nextState.auth.handleAuthentication();
  }
};

const RouteWhenAuthenticated = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(renderProps) => {
      if (auth.isAuthenticated()) {
        return <Component {...renderProps} />;
      }
      return <Redirect to={{ pathname: BabuRouteConst.login, state: { from: renderProps.location } }} {...rest} />
    }}
  />
);

class AppRouter extends Component {
  componentDidMount() {
    auth.dispatch = this.props.dispatch;
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path={BabuRouteConst.index} component={Landing} />
          <Route exact path={BabuRouteConst.login} render={props => <Login auth={auth} {...props} />} />
          <Route
            exact
            path={BabuRouteConst.callback}
            render={(props) => {
              handleAuthentication(props);
              return <Callback {...props} />;
            }}
          />
          <Route exact path={BabuRouteConst.callbackGoogle} component={CallbackGoogle} />
          <Route exact path={BabuRouteConst.callbackDropbox} component={CallbackDropbox} />
          <RouteWhenAuthenticated path={`${BabuRouteConst.home}/:menu?`} component={Drawer} auth={auth} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

function select(state) {
  return {
    User: state.User,
  };
}

export default connect(select)(AppRouter);
