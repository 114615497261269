import React, { Component } from 'react';
import loading from './loading.svg';
import axios from 'axios';
import config from '../../config';

const queryString = require('query-string');
class CallbackDropbox extends Component {
  componentDidMount() {
    const { location } = this.props
    const parsed = queryString.parse(location.search);
    const { state, code } = parsed;
    this.connect(state, code);
  }

  connect(state, code) {
    const url = config.REDIRECT_URI;
    const id_token = localStorage.getItem('id_token') || '';
    const access_token = localStorage.getItem('access_token') || '';
    const headers = {
      at: access_token,
      it: id_token,
      t: 'g',
    };
    const params = {
      state, 
      code
    };

    axios
      .get(url, { headers, params })
      .then(res => {
        //alert(JSON.stringify(res));
        console.log(res)
        window.close();
      })
      .catch(err => {
        alert(JSON.stringify(err));
        window.close();
      });
  }

  render() {
    const style = {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white',
    };

    return (
      <div style={style}>
        <img src={loading} alt="loading" />
      </div>
    );
  }
}

export default CallbackDropbox;
