import React from 'react';

export default () => (
  <div className="width100 margintop50 bgcolorblack paddingtb25 bottom">
    <div className="width90 marginauto">
      <div className="col-md-4 textleft rsp_textalign_left">
        <a className="fontwhitecolor fontsize1p1 paddinglr20">About Babu</a>
        <a className="fontwhitecolor fontsize1p1 paddinglr20">Privacy Policy</a>
      </div>
      <div className="col-md-4 textcenter rsp_textalign_left rsp_padding_bottom">
        <span className="fontwhitecolor fontsize1p1">Follow us:</span>
        <a className="paddinglr10" href="https://www.facebook.com">
          <img src="img/facebook.png" alt="facebook" />
        </a>
        <a className="paddinglr10" href="https://www.twitter.com">
          <img src="img/twitter.png" alt="facebook" />
        </a>
        <a className="paddinglr10" href="https://www.instagram.com">
          <img src="img/instagram.png" alt="facebook" />
        </a>
      </div>
      <div className="col-md-4 textright fontblackcolor fontsize1p1 rsp_textalign_left rsp_padding_bottom">
        © Babu 2017 – All Right Reserved
      </div>
      <div className="clearboth"></div>
    </div>
  </div>
);
