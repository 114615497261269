import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AccountComponent as AC } from '../components';
import { UserActions } from '../actions';
import config from '../config';
import Api from '../services/Api';
import { vis } from '../libs/vls';

class Account extends Component {
  constructor(props) {
    super(props);

    this.responseDropbox = this.responseDropbox.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseiCloud = this.responseiCloud.bind(this);
    this.responseOneDrive = this.responseOneDrive.bind(this);
    this.responseMega = this.responseMega.bind(this);

    this.state = {
      googleIsConnected: false,
      dropboxIsConnected: false,
      email: ''
    }

    vis(this.onTabAcitivated);
  }

  onTabAcitivated = () => {
    if (vis()) {
      // tween resume() code goes here	
      setTimeout(() => {            
        console.log("tab is visible - has focus");
        this.checkConnected();
      }, 300);		
    } else {
      // tween pause() code goes here
      console.log("tab is invisible - has blur");		 
    }
  }

  componentWillMount() {
    const { User } = this.props;
    const email = User.profile.email || localStorage.getItem('email')

    this.setState({ email });
  }

  componentDidMount () {
    this.checkConnected();
  }

  checkConnected() {
    this.checkGoogleIsConnected();
    this.checkDropboxIsConnected();
  }

  checkGoogleIsConnected() {
    const { email } = this.state

    Api
      .checkGoogleIsConnected(email)
      .then(res => {
        const { connected } = res.data
        this.setState({ googleIsConnected: connected });
      })
      .catch(err => console.log(err));
  }

  checkDropboxIsConnected() {
    const { email } = this.state

    Api
      .checkDropboxIsConnected(email)
      .then(res => {
        const { connected } = res.data
        this.setState({ dropboxIsConnected: connected });
      })
      .catch(err => console.log(err));
  }

  responseDropbox(err, token) {
    if (err) {
      console.log(err);
    } else {
      this.props.dispatch(UserActions.setDropboxToken(token));
    }
  }

  responseGoogle(err, token) {
    console.log(err, token);
  }

  responseiCloud() {}

  responseOneDrive() {}

  responseMega() {}

  renderDropboxButton() {
    const { dropboxIsConnected, email } = this.state

    const authUrl = config.DROPBOX_AUTH_URL.replace('<username>', email) + '&' + 
      'redirect_uri='+config.ACCOUNT_AUTH_REDIRECT_URI_DBOX

    return (
      <AC.DropboxButton
        email={email}
        className="_button btn-social btn-dropbox"
        style={{ width: 270 }}
        clientId="4ycnvwv5b8p72g7"
        connected={dropboxIsConnected}
        onSuccess={this.responseDropbox}
        onFailure={this.responseDropbox}
        redirectUri={config.ACCOUNT_AUTH_REDIRECT_URI_DBOX}
        authUrl={authUrl}
      />
    );
  }

  renderGoogleDriveButton() {
    const { googleIsConnected } = this.state

    return (
      <AC.GoogleDriveButton
        clientId={config.GOOGLE_CLIENT_ID}
        connected={googleIsConnected}
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogle}
      />
    );
  }

  renderGoogleConnectButton() {
    const { googleIsConnected, email } = this.state

    const authUrl = config.GOOGLE_AUTH_URL.replace('<username>', email) + '&' + 
      'redirect_uri='+config.ACCOUNT_AUTH_REDIRECT_URI_GOOGLE

    return (
      <AC.GoogleConnectButton
        email={email}
        authUrl={authUrl}
        connected={googleIsConnected}
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogle}
      />
    );
  }

  renderiCloudDriveButton() {
    return (
      <AC.iCloudDriveButton
        onSuccess={this.responseiCloud}
        onFailure={this.responseiCloud}
      />
    );
  }

  renderOneDriveButton() {
    return (
      <AC.OneDriveButton
        onSuccess={this.responseOneDrive}
        onFailure={this.responseOneDrive}
      />
    );
  }

  renderMegaButton() {
    return (
      <AC.MegaButton
        onSuccess={this.responseMega}
        onFailure={this.responseMega}
      />
    );
  }

  render() {
    return (
      <div className="body">
        <h2 className="textcenter fontcolorblue1 paddingtb30">
          Connect Apps to Babu
        </h2>
        <div className="connect_block">
          { this.renderGoogleConnectButton() }
          { this.renderDropboxButton() }
        </div>
      </div>
    );
  }
}

function select(state) {
  return {
    User: state.User,
  };
}

export default connect(select)(Account);
