import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GoogleButton extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.state = {
      disabled: true,
    };
  }

  componentDidMount() {
    const {
      clientId,
      cookiePolicy,
      loginHint,
      hostedDomain,
      autoLoad,
      isSignedIn,
      fetchBasicProfile,
      redirectUri,
      discoveryDocs,
      onFailure,
      uxMode,
      scope,
    } = this.props;

    ((d, s, id, cb) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      js = d.createElement(s);
      js.id = id;
      js.src = '//apis.google.com/js/client:platform.js';
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = cb;
    })(document, 'script', 'google-login', () => {
      const params = {
        client_id: clientId,
        cookie_policy: cookiePolicy,
        login_hint: loginHint,
        hosted_domain: hostedDomain,
        fetch_basic_profile: fetchBasicProfile,
        discoveryDocs,
        ux_mode: uxMode,
        redirect_uri: redirectUri,
        scope,
      };
      console.log(redirectUri)
      window.gapi.load('auth2', () => {
        this.setState({ disabled: false });
        if (!window.gapi.auth2.getAuthInstance()) {
          window.gapi.auth2
            .init(params)
            .then((res) => {
              if (isSignedIn && res.isSignedIn.get()) {
                this._handleSigninSuccess(res.currentUser.get());
              }
            })
            .catch(err => onFailure(err));
        }
        if (autoLoad) {
          this.signIn();
        }
      });
    });
  }
  signIn(e) {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    if (!this.state.disabled) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      const { onSuccess, onRequest, onFailure, prompt, responseType } = this.props;
      const options = {
        prompt,
      };
      onRequest();
      if (responseType === 'code') {
        auth2
          .grantOfflineAccess(options)
          .then(res => onSuccess(res))
          .catch(err => onFailure(err));
      } else {
        auth2
          .signIn(options)
          .then(res => this._handleSigninSuccess(res))
          .catch(err => onFailure(err));
      }
    }
  }

  _handleSigninSuccess(res) {
    console.log(res);
    /*
     offer renamed response keys to names that match use
     */
    const basicProfile = res.getBasicProfile();
    const authResponse = res.getAuthResponse();
    res.googleId = basicProfile.getId();
    res.tokenObj = authResponse;
    res.tokenId = authResponse.id_token;
    res.accessToken = authResponse.access_token;
    res.profileObj = {
      googleId: basicProfile.getId(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName(),
    };
    this.props.onSuccess(null, res);
  }

  render() {
    return (
      <div className="each_block paddingtb25">
        <div className="floatleft">
          <img src="/img/google_drive.png" alt="google_drive" />
        </div>
        <div className="floatright">
          <a
            className="inlineblock fontwhitecolor bgcolorgreen textcenter borderradius3 connectbutton"
            onClick={() => this.signIn()}
          >
            CONNECT
          </a>
        </div>
        <div className="clearboth" />
      </div>
    );
  }
}

GoogleButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  onRequest: PropTypes.func,
  scope: PropTypes.string,
  redirectUri: PropTypes.string,
  cookiePolicy: PropTypes.string,
  loginHint: PropTypes.string,
  hostedDomain: PropTypes.string,
  fetchBasicProfile: PropTypes.bool,
  autoLoad: PropTypes.bool,
  disabled: PropTypes.bool,
  discoveryDocs: PropTypes.array,
  uxMode: PropTypes.string,
  isSignedIn: PropTypes.bool,
  responseType: PropTypes.string,
  type: PropTypes.string
};

GoogleButton.defaultProps = {
  scope: 'profile email',
  prompt: '',
  cookiePolicy: 'single_host_origin',
  fetchBasicProfile: true,
  isSignedIn: false,
  uxMode: 'popup',
  onRequest: () => {},
};

export default GoogleButton;
