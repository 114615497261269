import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Data from './Data';
import Nav from './Nav';
import Filters from './Filters';
import User from './User';

const CombinedReducer = combineReducers({
  Data, Nav, Filters, User, Router: routerReducer,
});

export default CombinedReducer;
