import {
  SHOW_SIDE_BAR,
} from '../constants/ActionConstants';

// const assign = Object.assign || require('object.assign');

const initialState = {
  sideBar: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW_SIDE_BAR:
      return Object.assign({}, state, { sideBar: action.show });
    default:
      return state;
  }
};
