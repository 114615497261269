import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollMagic from 'scrollmagic';
import $ from 'jquery';

class Navbar extends Component {
  componentDidMount() {
    // this.scrollAnimation();
  }

  componentDidUpdate() {
    // this.scrollAnimation();
  }

  scrollAnimation() {
    // animation controller
    const stickyController = new ScrollMagic.Controller();

    // sticky nav
    const nav = new ScrollMagic.Scene({ triggerElement: '#nav-tr', triggerHook: 'onLeave' });
    nav
      .setPin('#nav')
      .setClassToggle('#nav', '--pinned') // add class toggle
      .addTo(stickyController);


    // sidebar element
    // const sidebar = new ScrollMagic.Scene({ triggerElement: '._sidebar-tr', triggerHook: 'onLeave' })
    //   .setClassToggle('._sidebar', '--pinned') // add class toggle
    //   .addTo(stickyController);

    const scrollElement = 'html, body';

    $("._nav a[href^='#']").click((event) => {
      event.preventDefault();
      const $this = $(event.target);
      const target = event.target.hash;
      const $target = $(target);
      const offset = $this.data('offset-scroll') || 0; // <... data-offset-scroll="400">*/}

      if (typeof $target.offset() !== 'undefined') {
        // window.location.hash = target;
        $(scrollElement).stop().animate({
          scrollTop: $target.offset().top + offset,
        }, 500, 'swing', () => {
          event.preventDefault();
          // window.location.hash = target;
        });
      }
    });
  }

  render() {
    const { handleClickLogin } = this.props;

    return (
      <div className="header header_bgcolor paddingtb25">
        <nav className="navbar">
          <div>
            <div className="navbar-header">
              <a>
                <img src="img/logo.png" className="logo" alt="logo"/>
              </a>
              <button type="button" className="navbar-toggle">
                <span className="sr-only">Menu</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
            </div>
            <ul className="nav navbar-nav">
              <li className="active bold">
                <a className="fontcolorgray1">About</a>
              </li>
              <li className="active bold">
                <a className="fontcolorgray1">Security</a>
              </li>
              <li className="active bold paddingleft15">
                <a className="fontsize095 fontcolorgreen1 loginbutton" onClick={handleClickLogin}>
                  LOG IN
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.PropTypes = {
  navClick: PropTypes.func,
};

export default Navbar;
