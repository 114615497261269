import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OneDriveButton extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.state = {
      disabled: true,
    };
  }

  componentDidMount() {}

  _handleSigninSuccess(res) {
    this.props.onSuccess(res);
  }

  render() {
    return (
      <div className="each_block paddingtb25">
        <div className="floatleft">
          <img src="/img/onedrive.png" alt="onedrive" />
        </div>
        <div className="floatright">
          <span 
            className="inlineblock fontcolorgray5 textcenter borderradius3 bordercolorgray1 connectedbutton"
            disabled>
            CONNECTED
          </span>
        </div>
        <div className="clearboth" />
      </div>
    );
  }
}

OneDriveButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

OneDriveButton.defaultProps = {
};

export default OneDriveButton;
