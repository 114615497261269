import React, { Component } from 'react';
import PropTypes from 'prop-types';

class iCloudButton extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.state = {
      disabled: true,
    };
  }

  componentDidMount() {}

  _handleSigninSuccess(res) {
    this.props.onSuccess(res);
  }

  render() {
    return (
      <div className="each_block paddingtb25">
        <div className="floatleft">
          <img src="/img/icloud_drive.png" alt="icloud_drive" />
        </div>
        <div className="floatright">
          <span
            className="inlineblock fontcolorgray5 textcenter borderradius3 bordercolorgray1 connectedbutton"
          >
          CONNECTED</span>
        </div>
        <div className="clearboth" />
      </div>
    );
  }
}

iCloudButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

iCloudButton.defaultProps = {
};

export default iCloudButton;
