import { put, takeLatest } from 'redux-saga/effects';
import {
  ELASTIC_SEARCH_SUCCESS,
  BUILD_FILTER,
} from '../constants/ActionConstants';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* buildFilter(action) {
  yield put({ type: BUILD_FILTER, data: action.data });
}

/*
 Alternatively you may use takeLatest.

 Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
 dispatched while a fetch is already pending, that pending fetch is cancelled
 and only the latest one will be run.
 */
function* searchDataSaga() {
  yield takeLatest(ELASTIC_SEARCH_SUCCESS, buildFilter);
}

export default searchDataSaga;
