import React from 'react';
import SocialLoginButtonProvider from 'react-social-login-buttons/lib/buttons/SocialLoginButtonProvider';
import './button.css';

const defaults = {
  text: 'Login with Auth0',
  icon: 'lock',
  iconFormat: name => `auth0-button fa-${name}`,
  style: { background: '#983d00' },
  activeStyle: { background: '#4d2600' },
};

/** Auth0 login button. */
const Auth0Button = props => (
  <SocialLoginButtonProvider defaults={defaults} props={props} />
);

export default Auth0Button;
