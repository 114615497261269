const BabuRouteConst = {
  index: '/',
  login: '/login',
  home: '/home',
  homeApps: '/home/apps',
  homeContact: '/home/contact',
  callback: '/callback',
  callbackGoogle: '/callback/google',
  callbackDropbox: '/callback/dropbox',
  /* any */
  http404: '*',
};

export default BabuRouteConst;
