const AppConst = {
  ItemsPerPage: 6,
  DirectPages: [1, 2, 3],
  PageItemsPerPage: 5,
  menus: {
    home: 'home',
    apps: 'apps',
    contact: 'contact',
    logout: 'logout'
  }
};

export default AppConst;
