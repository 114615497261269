import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MegaButton extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.state = {
      disabled: true,
    };
  }

  componentDidMount() {}

  _handleSigninSuccess(res) {
    this.props.onSuccess(res);
  }

  render() {
    return (
      <div className="each_block border0 paddingtb25">
        <div className="floatleft">
          <img src="/img/mega.png" alt="mega" />
        </div>
        <div className="floatright">
          <a className="inlineblock fontwhitecolor bgcolorgreen textcenter borderradius3 connectbutton">CONNECT</a>
        </div>
        <div className="clearboth" />
      </div>
    );
  }
}

MegaButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

MegaButton.defaultProps = {
};

export default MegaButton;

