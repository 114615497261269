import React from 'react';

const HeaderMenu = ({menus, selectedMenu, onClick}) => (
  <div className="header header_bgcolor_app paddingtb10">
    <nav className="navbar">
      <div className="navbar-header">
        <a><img src="img/logo.png" className="logo" alt="logo"/></a>
        <ul className="nav navbar-nav paddingleft30">
          {
            menus.map(menu => {
              const selected = selectedMenu === menu.key;
              const className = `${selected ? 'fontcolorgray4' : 'fontcolorgray1'} fontsize1p1 inlineblock ${selected ? 'relativeblock' : ''}`
              return (
                <li className={selected ? "active" : ""} key={menu.key}>
                  <a className={className}
                     onClick={() => onClick(menu.key)}
                  >
                    { menu.title }
                    { selected ? <div className="selected_line relativeblock" /> : null }
                  </a>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className="floatright margintop10">
        <a className="inlineblock fontwhitecolor fontsize095 bgcolorgray1 borderradius5 signoutbutton"
           onClick={() => onClick('logout')}
        >
          SIGN OUT
        </a>
      </div>
      <div className="clearboth" />
    </nav>
  </div>
);

export default HeaderMenu;
